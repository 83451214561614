import React, { Component } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Button
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as mainActions from "../actions/main";
import { connect } from "react-redux";
import { ExitToApp } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import logo from "../resources/logo-reseller.png";
import logo_right from "../resources/farmatic.png";


class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    setLogoutAlert = open => {
        this.setState({ open: open });
    };

    render() {
        const hideLogout = this.props;
        return (
            <>
                <AppBar
                    position="fixed"
                    style={{ height: "3em", backgroundColor: "whitesmoke", color: "white" }}>
                    <Toolbar
                        style={{ marginTop: "-0.25em", justifyContent: "space-between" }}>
                        <Typography variant="h4">
                            <img
                                width="80"
                                style={{ marginTop: "0.3em" }}
                                alt="CocodinPharma"
                                src={logo}
                            />
                        </Typography>
                        <Typography type="title" color="primary" style={{ fontSize: '18px', flex: 1, paddingLeft: 15, color: '#01A0E3' }}>
                            Incidencias Farmacia
                        </Typography>
                        <Typography variant="subtitle2">
                            <img
                                width="100"
                                style={{ marginTop: "0.3em" }}
                                alt="Farmatic"
                                src={logo_right}
                            />
                        </Typography>
                        <Typography variant="h4" />
                        {!hideLogout && (
                            <IconButton
                                style={{ color: "white", float: "right" }}
                                onClick={() => {
                                    this.setLogoutAlert(true);
                                }}>
                                <ExitToApp />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
                <Dialog
                    open={this.state.open}
                    onClose={() => {
                        this.setLogoutAlert(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Cerrar sesión</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Seguro que quieres cerrar sesión?
            </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setLogoutAlert(false);
                            }}>
                            No
            </Button>
                        <Button onClick={this.props.actions.logout} autoFocus>
                            Si
            </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    };
}

const mapStateToProps = state => ({
    main: state.main
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);
