import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";
import * as mainActions from '../actions/main';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Card from '@material-ui/core/Card';
import { Save } from '@material-ui/icons';
import API_URL from '../config/constants';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ReCAPTCHA from 'react-google-recaptcha';
import {DropzoneArea} from 'material-ui-dropzone'
import { AttachFile } from '@material-ui/icons';

const CLASIFICATIONS_DENO = 'descripcion';


const USER_PASS = 'valsof%3478LK23';
const CAPTCHA_KEY = '6LfdoAkUAAAAAGDhY5-TWbASDysMjoyR4POldwu2';
const PRIVACY_URL = 'https://valsoft.es/politica-de-privacidad/';

class FormPharmacy extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            captchaOK : false, //put to true in development mode to avoid captcha confirmation
            privacyOK : false,
            saving: false,
            dropzonekey : new Date().getTime().toString(),
            form: {
                nombre: '',
                contacto: '',
                tlf: '',
                email: '',
                clasificacion: '',
                obs: '',
                files: [],
            },
            errors: {
                nombre: '',
                contacto: '',
                tlf: '',
                email: '',
                clasificacion: '',
                obs: '',
            },
            types: [],
            clasifications: [{ codex: "000001", deno: "Servicio técnico" }, { codex: "000002", deno: "Dudas farmatic" }]
        }
        this._reCaptchaRef = React.createRef();
    }

    async componentDidMount() {
        const requestTipos = new Request(`${API_URL}/getTipos`, {
            method: 'POST',
            body: JSON.stringify({
                username: USER_PASS,
                password: USER_PASS,
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
        const responseTipos = await fetch(requestTipos);
        const jsonTipos = await responseTipos.json();

        const requestClasificaciones = new Request(`${API_URL}/getClasificaciones`, {
            method: 'POST',
            body: JSON.stringify({
                username: USER_PASS,
                password: USER_PASS,
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
        const responseClasificaciones = await fetch(requestClasificaciones);
        const jsonClasificaciones = await responseClasificaciones.json();
        this.setState({ types: jsonTipos.result.data.hd_tipos.data, clasifications: jsonClasificaciones.result.data.clasificacion.data})
    }

    setValue = (event) => {
        const { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form });
    }

    save = async () => {
        if (this.validateFields()) {
            this.setState({saving: true});
            const formData = this.state.form;
            const request = new Request(`${API_URL}/insert`, {
                method: 'POST',
                body: JSON.stringify({
                    username: USER_PASS,
                    password: USER_PASS,
                    data: formData
                }),
                headers: new Headers({
                    'Content-Type': 'application/json'
                })
            });
            const response = await fetch(request);
            const json = await response.json();
            this.setState({saving: false});

            if (json.result.data.res === 200) {
                this.resetForm();
                return toast("OK, incidencia generada correctamente.", {
                    className: "toast-success"
                });
            } else if (json.result.data.res === 501) {
                return toast("No se puede identificar al cliente con los datos proporcionados.", {
                    className: "toast-error"
                });
            } else if (json.code === 400) {
                console.error(json.msg);
                return toast(json.msg, {
                    autoClose: false,
                    position: "top-center",
                    className: "toast-error"
                });
            }
            else {
                console.error(json.result.data.info);
                return toast(json.result.data.info, {
                    autoClose: false,
                    position: "top-center",
                    className: "toast-error"
                });
            }
        }
    }

    resetForm = () => {
        let newForm = {};
        let newErrors = {};

        const formFields = Object.keys(this.state.form);
        formFields.forEach(field => {
            newErrors[field] = ''
            newForm[field] = ''
        });
        newForm.files = [];
        //this.setDropzonekey();
        this.setState({ dropzonekey: new Date().getTime().toString(), form: newForm, errors: newErrors });
    }

    validateFields = () => {
        const formData = this.state.form;
        const formFields = Object.keys(this.state.form);
        let newErrors = {}
        let existsErrors = false;
        formFields.forEach(field => {
            if (!formData[field]) {
                existsErrors = true;
                newErrors[field] = 'No puede estar vacío'
            } else {
                newErrors[field] = ''
            };
        });

        this.setState({ errors: newErrors });
        return !existsErrors;
    }

    onChangeCheckboxPrivacy = (event) => {
        this.setState({privacyOK : event.target.checked});
    }

    onChangeFiles = (files) => {
        //console.log(files);
        const newFiles = [];
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                //console.log(file.type);
                const newFile = {};
                //console.log(JSON.stringify(event.target));
                newFile.base64 = event.target.result.split(',')[1]; //get only base64 content
                newFile.type = event.target.result.split(';')[0].split(':')[1]; //remove data:image/.... 
                //console.log(newFile.type);
                //console.log(newFile.base64.substring(0,20));
                newFiles.push(newFile);
                this.setState(prevState => ({
                    form: {                   // object that we want to update
                        ...prevState.form,    // keep all other key-value pairs
                        files: newFiles,      // update the value of specific key
                    }
                }));
            };
            reader.readAsDataURL(file);
         });

        //console.log(this.state.form.files);
    }

    onCaptchaOK = () => {
        this.setState({captchaOK : true});
    }

    render() {
        const { form, errors, clasifications, saving, captchaOK, dropzonekey, privacyOK } = this.state;
        return (
            <>   
                <Card style={{ margin: '5em 8vw', padding: '1em' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <span style={{ weight: 'bold', fontSize: '20px', color: '#01A0E3' }}>Formulario de incidencia</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ weight: 'bold', fontSize: '16px', color: 'darkgrey' }}>Datos de identificación del cliente:</span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    id="nombre"
                                    name="nombre"
                                    label="Nombre del Cliente / Farmacia"
                                    value={form.nombre ? form.nombre : ""}
                                    margin="dense"
                                    type="text"
                                    onChange={this.setValue}
                                    helperText={errors.nombre && errors.nombre !== "" ? errors.nombre : ""}
                                    error={errors.nombre && errors.nombre !== "" ? true : false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    id="contacto"
                                    name="contacto"
                                    label="Persona de contacto"
                                    value={form.contacto ? form.contacto : ""}
                                    margin="dense"
                                    type="text"
                                    onChange={this.setValue}
                                    helperText={errors.contacto && errors.contacto !== "" ? errors.contacto : ""}
                                    error={errors.contacto && errors.contacto !== "" ? true : false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    id="tlf"
                                    name="tlf"
                                    label="Teléfono"
                                    value={form.tlf ? form.tlf : ""}
                                    margin="dense"
                                    placeholder="Teléfono (Ejemplo: 932264826)"
                                    type="text"
                                    onChange={this.setValue}
                                    helperText={errors.tlf && errors.tlf !== "" ? errors.tlf : ""}
                                    error={errors.tlf && errors.tlf !== "" ? true : false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={form.email ? form.email : ""}
                                    margin="dense"
                                    type="text"
                                    onChange={this.setValue}
                                    helperText={errors.email && errors.email !== "" ? errors.email : ""}
                                    error={errors.email && errors.email !== "" ? true : false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{ weight: 'bold', fontSize: '16px', color: 'darkgrey' }}>Clasificación de la incidencia:</span>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset"  style={{ width: '100%', fontSize: '16px' }} error={errors.clasificacion !== ''}>
                                <RadioGroup
                                    style={{ fontSize: '16px'}}
                                    aria-label="clasificacion"
                                    name="clasificacion"
                                    value={form.clasificacion ? form.clasificacion : ""}
                                    onChange={this.setValue}
                                >
                                {clasifications.map(clasification => {
                                    return (
                                        <FormControlLabel style={{ fontSize: '16px'}} key={`clasification_${clasification.codex}`} value={clasification.codex} control={<Radio color="default" />} label={<Typography style={{ fontSize: '16px',color: 'balck' }}>{clasification[CLASIFICATIONS_DENO]}</Typography>} />
                                    )
                                })}       

                                </RadioGroup>
                                <FormHelperText>{errors.clasificacion}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    multiline
                                    id="obs"
                                    name="obs"
                                    label="Descripción de la incidencia"
                                    value={form.obs ? form.obs : ""}
                                    margin="dense"
                                    type="text"
                                    onChange={this.setValue}
                                    helperText={errors.obs && errors.obs !== "" ? errors.obs : ""}
                                    error={errors.obs && errors.obs !== "" ? true : false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <span style={{ weight: 'bold', fontSize: '16px', color: 'darkgrey' }}>Añadir imágenes (Opcional):</span>
                            <FormControl style={{ width: '100%', padding: '5px' }}>
                                <DropzoneArea
                                    key = {dropzonekey}
                                    acceptedFiles={['image/*']}
                                    maxFileSize={6000000}
                                    dropzoneClass="dropzone"
                                    dropzoneText={"Arrastre o pulse para añadir hasta 3 imágenes"}
                                    showPreviews={true}
                                    clearOnUnmount={true}
                                    Icon={AttachFile}
                                    showPreviewsInDropzone={false}
                                    showFileNamesInPreview={false}
                                    previewText="Previsualización:"
                                    onChange={this.onChangeFiles.bind(this)}
                                    showAlerts={false}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel style={{ float: 'right'}} label="Acepto la política de privacidad de esta web"
                                    control={<Checkbox onChange={this.onChangeCheckboxPrivacy.bind(this)} color="default"/>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button style={{ float: 'right', backgroundColor: '#01A0E3' }}
                                disabled={saving || !captchaOK || !privacyOK}
                                size="large"
                                variant="contained"
                                color="secondary"
                                onClick={this.save}>
                            <Save />Enviar incidencia</Button>

                                <ReCAPTCHA style={{ float: 'right'}}
                                    ref={this.recaptchaRef}
                                    size="compact"
                                    sitekey={CAPTCHA_KEY}
                                    onChange={this.onCaptchaOK}
                                />
                        </Grid>
                    </Grid>

                </Card>
                <Card style={{ textAlign: 'center', width: '100%', bottom: 0, left: 0}}>
                    <Grid item xs={12} md={12} style={{ width: '100%', paddingBottom: '20px' }}>
                        <Link href={PRIVACY_URL} color="default" align="center">Política de privacidad</Link>
                    </Grid>
                </Card>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    main: state.main
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPharmacy);
