import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from "react-toastify";
import * as mainActions from '../actions/main';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Save from '@material-ui/icons/Save';

import MoodBad from '@material-ui/icons/MoodBad';
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfied';
import Mood from '@material-ui/icons/Mood';


import API_URL from '../config/constants';
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import NavBar from '../components/NavBar';


class Satisfaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            loading: true,
            token: '',
            suffrage: [],
            isCompleteSent: true,
            notFound: false
        }
    }

    async componentDidMount() {
        let token = this.props.match.params.token;
        const request = new Request(`${API_URL}/checkToken`, {
            method: 'POST',
            body: JSON.stringify({
                token
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });

        const response = await fetch(request);
        const json = await response.json();

        if (json.code === 200 && json.result.data.res.length>0) {
            const isCompleteSent = this.checkCompleteSent(json.result.data.res);
            this.setState({ isCompleteSent: isCompleteSent, loading: false, token, suffrage: json.result.data.res })
        } else {
            this.setState({ isCompleteSent: true, loading: false, notFound: true })
        }
    }

    setValue = (value, index) => {
        const newArray = this.state.suffrage;
        newArray[index].newvalue = value;
        //console.log('question ' + (index +1) + ': ' + value);
        this.setState({ suffrage : [...newArray] });
    }

    checkCompleteSent = (data) => {
        for (let i=0;i<data.length; i++) {
            const item = data[i];
            if (item.value === '' || item.value === '0') {
                return false;
            }
        }
        return true;
    }

    save = async () => {
        this.setState({ saving: true });
        const { token, suffrage } = this.state;
        console.log("suffrage:" + JSON.stringify(suffrage));
        const request = new Request(`${API_URL}/insertPoll`, {
            method: 'POST',
            body: JSON.stringify({
                token,
                suffrage
            }),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        });
        const response = await fetch(request);
        const json = await response.json();
        if (json.code === 200 && json.result.data.code===200) {
            this.setState({ saving: false });
            //console.log("remote suffrage:" + JSON.stringify(json.result.data.res));
            const isCompleteSent = this.checkCompleteSent(json.result.data.res);
            this.setState({ suffrage: json.result.data.res, isCompleteSent: isCompleteSent });
            return toast("OK, ha votado correctamente.", {
                className: "toast-success"
            });
        } else {
            this.setState({ saving: false });
            const msg = (json.result && json.result.data.msg) ? json.result.data.msg : "No se puede realizar la operación.";
            return toast(msg, {
                className: "toast-error",
                position: "top-center",
                autoClose: false,
            });
        }
    }

    render() {
        const { suffrage, loading, notFound, saving, isCompleteSent } = this.state;
        return (
            <>
                <NavBar hideLogout />
                <div style={{ margin: '6em auto', width: '80%' }}>
                    {loading ?
                        <div style={{ textAlign: 'center' }}>Loading...</div>
                        :
                        <>
                            {
                                notFound ?
                                    <div>La valoración indicada no se ha encontrado.</div>
                                    :
                                    <Card style={{ margin: '5em 4vw', padding: '1em' }}>
                                        {
                                        suffrage.map((object, i) => 
                                        <Grid item xs={12} key={object.codex}>
                                            <h3>{suffrage.length>1 ? (i+1 + '.') : ''}{object.question}</h3>
                                            {
                                                (object.value !== '0' && object.value !== '') ?
                                                <p style={{ fontWeight: 'italic', padding: '0.5em' }}>Esta pregunta ya ha sido enviada.</p>
                                                :
                                                <p style={{ fontWeight: 'italic', padding: '0.5em' }}></p>
                                            }
                                            <IconButton style={{ color: object.value ==='1' || object.newvalue ==='1' ? '#009eb3' : 'black' }} disabled={object.value !== '0' && object.value !== ''} onClick={() => { this.setValue('1',i) }}>
                                                <span style={{ fontWeight: 'bold', padding: '0.5em' }}><MoodBad style={{ fontSize: '3em' }} /></span>
                                            </IconButton>
                                            <IconButton style={{ color: object.value ==='2' || object.newvalue ==='2' ? '#009eb3' : 'black' }} disabled={object.value !== '0' && object.value !== ''} onClick={() => { this.setValue('2',i) }}>
                                                <span style={{ fontWeight: 'bold', padding: '0.5em' }}><SentimentDissatisfied style={{ fontSize: '3em' }} /></span>
                                            </IconButton>
                                            <IconButton style={{ color: object.value ==='3' || object.newvalue ==='3' ? '#009eb3' : 'black' }} disabled={object.value !== '0' && object.value !== ''} onClick={() => { this.setValue('3', i) }}>
                                                <span style={{ fontWeight: 'bold', padding: '0.5em' }}><SentimentSatisfied style={{ fontSize: '3em' }} /></span>
                                            </IconButton>
                                            <IconButton style={{ color: object.value ==='4' || object.newvalue ==='4' ? '#009eb3' : 'black' }} disabled={object.value !== '0' && object.value !== ''} onClick={() => { this.setValue('4', i) }}>
                                                <span style={{ fontWeight: 'bold', padding: '0.5em' }}><Mood style={{ fontSize: '3em' }} /></span>
                                            </IconButton>
                                        </Grid>
                                        )
                                        }
                                        {
                                            !isCompleteSent &&
                                            <Grid item xs={12}>
                                                <br/>
                                                <Button style={{ backgroundColor: '#01A0E3' }}
                                                    disabled={saving}
                                                    size="large"
                                                    variant="contained" 
                                                    color="secondary"
                                                    onClick={this.save}>
                                                    <Save />Enviar valoración</Button>
                                            </Grid>
                                        }
                                    </Card>
                            }
                        </>
                    }
                </div>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    main: state.main
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Satisfaction));